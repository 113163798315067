(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
module.exports = {
    VALIDATOR_CUSTOM_INITIALIZE: 'VALIDATOR_CUSTOM_INITIALIZE',
    TRIGGER_CLICK_ORDER_DETAIL: 'TRIGGER_CLICK_ORDER_DETAIL'
}
},{}],2:[function(require,module,exports){
const EventNames = require('../../../../app_sergelutens/cartridge/js/constants/EventNames');

var $cache = {},
    options = {},
    initialized = false,
    kanji = /^[a-zA-Z0-9 ａ-ｚＡ-Ｚ０-９ぁ-ゔァ-ヴ一-龯㐀-䶵豈-舘㍻々ヶ〆\-ー_&/.'()]+$/,
    katakana = /^[a-zA-Z0-9 ａ-ｚＡ-Ｚ０-９ァ-ヴ\ー]+$/,
    romaji = /^[a-zA-Z0-9 ]+$/,
    romajiplus = /^[a-zA-Z0-9 \-_&\/.'()]+$/,
    postalcodewhitelist = {
        jp: SitePreferences.ADDRESS_JAPAN,
        other: '[]'
    },
    regex = {
        phone: {
            jp: /^([0-9]){10,13}$/,
            other: /[0-9]{10,13}/
        },
        postal: {
            jp: /^([0-9]){7}$/,
            other: /^([0-9]){7}$/
        },
        name: {
            jp: kanji,
            other: romajiplus
        },
        simpletext: {
            jp: kanji,
            other: romaji
        },
        password: /^(?=.*\d)(?=.*[a-zA-Z])[a-zA-Z0-9]{8,20}$/,
        katakana: {
            jp: katakana,
            other: romaji
        },
        kanji: {
            jp: kanji,
            other: romajiplus
        },
        romanji: {
            jp: kanji,
            other: romaji
        },
        romajiplus: {
            jp: kanji,
            other: romajiplus
        },
        creditCard: /^(\d(?!\*)|\*){0,18}\d$/,
        cvn: /^\d{3,4}$/,
        inquiry: /^[^<>\/]+$/
    };

/**
 * @function
 * @description Validates a given phone number against the countries phone regex
 * @param {String} value The phone number which will be validated
 * @param {String} el The input field
 */
var validatePhone = function(value, el) {
    var country = $(el).closest('form').find('.country option:selected').val()

    if (country == undefined || country.length === 0 || !regex.phone[country.toLowerCase()]) {
        country = 'other';
    }

    var rgx = regex.phone[country.toLowerCase()];
    var isOptional = this.optional(el);
    var isValid = rgx.test($.trim(value));

    // format the phone number
    return isOptional || isValid;
};

var validateExpirationDate = function(value, el) {
    var currentDate = new Date();
    var selectedMonth = $('select[name*="expiration_month"]').val(),
        selectedYear = $('select[name*="expiration_year"]').val();
    var selectedDate = new Date(selectedYear, selectedMonth, 1);
    if (selectedDate < currentDate) {
        return false;
    }
    return true;
};

/**
 * @function
 * @description Validates that kanji character
 * @param {String} value The kanji field which will be validated
 * @param {String} el The input field
 */
function validateKanji(value, el) {
    var country = $(el).closest('form').find('.country option:selected').val()

    if (country == undefined || country.length === 0 || !regex.kanji[country.toLowerCase()]) {
        country = 'other';
    }

    var rgx = regex.kanji[country.toLowerCase()];
    var isOptional = this.optional(el);
    var isValid = rgx.test($.trim(value));
    return isValid;
};

/**
 * @function
 * @description Validates that katakana character and Romanji
 * @param {String} value The katakana field which will be validated
 * @param {String} el The input field
 */
var validateKatakana = function(value, el) {
    var country = $(el).closest('form').find('.country option:selected').val()

    if (country == undefined || country.length === 0 || !regex.katakana[country.toLowerCase()]) {
        country = 'other';
    }

    var rgx = regex.katakana[country.toLowerCase()];
    var isValid = rgx.test($.trim(value));
    if (isRequired(el) === false && $.trim(value).length === 0) {
        isValid = true;
    }
    return isValid;
};

/**
 * @function
 * @description Validates a input field by byte
 * @param {String} value The input field which will be validated
 * @param {String} el The input field
 */
var validateByte = function(value, el) {
    var maxlength = $(el).prop('maxlength');
    var len = encodeURI(value).split(/%..|./).length - 1;
    if (len <= maxlength) {
        return true;
    }
};

var validateSimpleText = function(value, el) {
    var country = $(el).closest('form').find('.country option:selected').val()

    if (country == undefined || country.length === 0 || !regex.simpletext[country.toLowerCase()]) {
        country = 'other';
    }
    var rgx = regex.simpletext[country.toLowerCase()];

    var isValid = rgx.test($.trim(value));
    return isValid;
};

var validatePostal = function(value, el) {
    var country = $(el).closest('form').find('select.country');
    if (country.length === 0 || country.val().length === 0 || !regex.postal[country.val().toLowerCase()]) {
        return true;
    }

    var rgx = regex.postal[country.val().toLowerCase()];
    var isValid = rgx.test($.trim(value));

    var isValidateWhiteListPostal = true;
    if (isRequired(el) === true || $.trim(value).length > 0) {
        isValidateWhiteListPostal = validateWhiteListPostal(value, country);
    }
    var isOptional = this.optional(el);

    return isValidateWhiteListPostal && (isOptional || isValid);
};

var validateRomaji = function(value, el) {
    var country = $(el).closest('form').find('.country option:selected').val()

    if (country == undefined || country.length === 0 || !regex.romanji[country.toLowerCase()]) {
        country = 'other';
    }
    var rgx = regex.romanji[country.toLowerCase()];

    var isValid = rgx.test($.trim(value));
    if (isRequired(el) === false && $.trim(value).length === 0) {
        isValid = true;
    }
    return isValid;
};

var validateRomajiPlus = function(value, el) {
    var country = $(el).closest('form').find('.country option:selected').val()

    if (country == undefined || country.length === 0 || !regex.romajiplus[country.toLowerCase()]) {
        country = 'other';
    }
    var rgx = regex.romajiplus[country.toLowerCase()];

    var isValid = rgx.test($.trim(value));
    if (isRequired(el) === false && $.trim(value).length === 0) {
        isValid = true;
    }
    return isValid;
};

function validateWhiteListPostal(postcode, country) {
    var addressList = postalcodewhitelist[country.val().toLowerCase()];
    var addressListData = JSON.parse(addressList);

    if (addressListData.length > 0) {
        for (var i = 0; i < addressListData.length; i++) {
            if (addressListData[i] == postcode) {
                return true;
            }
        }
        return false;
    }
    return true;
}

function isRequired(element) {
    var result = false;
    if ($(element).hasClass('required')) {
        result = true;
    }
    return result;
}

var replaceTwoByteToOneByteNumber = function(value) {
    var fieldValue = value;
    var oneByteCharacters = '0,1,2,3,4,5,6,7,8,9,-'.split(',');
    var twoByteCharacters = '０,１,２,３,４,５,６,７,８,９,－'.split(',');

    for (var i = 0; i < fieldValue.length; i++) {
        var fieldChar = fieldValue.charAt(i);
        var towByteCharIdx = twoByteCharacters.indexOf(fieldChar);

        if (towByteCharIdx > -1 && (towByteCharIdx in oneByteCharacters)) {
            fieldValue = fieldValue.replace(new RegExp(twoByteCharacters[towByteCharIdx]), oneByteCharacters[towByteCharIdx]);
        }
    }

    return fieldValue;
};

var validateCreditCard = function(value, el) {
    var result = replaceTwoByteToOneByteNumber(value);
    return regex.creditCard.test(result);
};

function initializeConfig(params) {
    options = $.extend({}, options, params);
}

function initializeCache(params) {
    $cache.document = $(document);
}

var validateSpace = function(value, el) {
    var isValid = value.indexOf(" ") < 0;

    // check space
    return isValid;
};

function initializeEvents() {
    $.validator.addMethod('no-space', validateSpace, Resources.INVALID_PHONE);
    $.validator.addMethod('phone', validatePhone, Resources.INVALID_PHONE);
    $.validator.addMethod('firstname', validateKanji, Resources.VALIDATE_TEXT);
    $.validator.addMethod('lastname', validateKanji, Resources.VALIDATE_TEXT);
    $.validator.addMethod('katakana', validateKatakana, Resources.VALIDATE_TEXT);
    $.validator.addMethod('kanji', validateKanji, Resources.VALIDATE_TEXT);
    $.validator.addMethod('byte', validateByte, function(value, el) {
        var msg = Resources.VALIDATE_BYTE_FIRST + $(el).prop('maxlength') + Resources.VALIDATE_BYTE;
        return msg;
    });
    $.validator.addMethod('simpletext', validateSimpleText, Resources.VALIDATE_TEXT);
    $.validator.addMethod('postal', validatePostal, Resources.VALIDATE_POSTAL);
    $.validator.addMethod('romaji', validateRomaji, Resources.VALIDATE_TEXT);
    $.validator.addMethod('romajiplus', validateRomajiPlus, Resources.VALIDATE_TEXT);
    $.validator.addMethod('number', validateCreditCard, function(value, el) {
        if (replaceTwoByteToOneByteNumber($(el).val()).indexOf('-') > -1) {
            var msg = Resources.VALIDATE_CREDITCARD_HYPHEN;
        } else {
            var msg = Resources.VALIDATE_CREDITCARD;
        }
        return msg;
    });
    $.validator.addMethod('cvn', function(value, el) {
        var result = replaceTwoByteToOneByteNumber(value);
        return regex.cvn.test(result);
    }, Resources.VALIDATE_CVN);

    $.validator.addMethod('inquiry', function(value, el) {
        return regex.inquiry.test(value);
    }, Resources.VALIDATE_TEXT);

    $.validator.addMethod('expirationDate', validateExpirationDate, Resources.VALIDATE_EXPIRATION_DATE);

    $.validator.addClassRules({
        city: {
            simpletext: false
        },
        address1: {
            simpletext: false,
            address: false
        },
        province: {
            simpletext: false
        },
        address2: {
            address2: false
        }
    });
}

function init(e, params) {
    if (initialized) {
        return;
    }
    initializeConfig(params);
    initializeCache();
    initializeEvents();
    initialized = true;
}

const attachEvents = function() {
    $(window)
        .off("".concat(EventNames.VALIDATOR_CUSTOM_INITIALIZE, ".init"))
        .on("".concat(EventNames.VALIDATOR_CUSTOM_INITIALIZE, ".init"), init);
}

window.validatorCustomEventsHandler = {
    init: function() {
        attachEvents();
    }
}

window.validatorCustomEventsHandler.init();
},{"../../../../app_sergelutens/cartridge/js/constants/EventNames":1}]},{},[2]);
